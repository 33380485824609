/*jslint browser: true, nomen: true */
/*global $ */
'use strict';
$(function () {
  (new StickyFooter($('#container'), $('#footer'))).init().bindOnResize().bindOnLoad();
  (new ExternalLinkHandler()).addTargetAttribute($('body'));
  (new UIBindings()).bindTooltips();

  (new UIBindings()).bindPopovers();

  (new UIBindings()).bindSlickCarousels();
  (new UIBindings()).bindSubmittingButtons();
  (new UIBindings()).bindSharing();
  (new UIBindings()).bindMagnificPopup();
  (new FormElements()).dropdownMenu();
  (new Selectize()).selectizeSimple($('form').filter('[data-section-simple]'));
  (new Forms()).validate().bindEvents();
  (new PageTimer()).logToConsole();
  $('[data-affix]').customAffix();

  addToCalendar.init();

  campaign.init();
});


function PageTimer () {
  var self = this;
  this.getLoadTime = function () {
    var now = new Date().getTime();
    // Get the performance object
    window.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
    var timing = performance.timing || {};

    var ms = now - timing.navigationStart;

    return Math.round(ms / 10) / 100;
  };

  this.logToConsole = function () {
    $(window).on('load', function () {
      console && console.info && console.info("Loaded in " + self.getLoadTime() + 's');
    });
    return this;
  };

  this.append = function (holder) {
    $(window).on('load', function () {
      holder.text('LT: ' + self.getLoadTime() + 's');
    });

    return this;
  }
}


function StickyFooter ($wrap, $footer) {
  var $window = $(window);

  this.updateWrapperCSS = function () {
    var footerHeight = $footer.outerHeight();

    $wrap.css({
      marginBottom: -1 * footerHeight,
      paddingBottom: footerHeight
    });
  };

  this.bindOnResize = function () {
    $window.on('resize', this.updateWrapperCSS);

    return this;
  };

  this.bindOnLoad = function () {
    $window.on('load', this.updateWrapperCSS);

    return this;
  };

  this.init = function () {
    this.updateWrapperCSS();

    $footer.removeClass('page-loading');

    return this;
  };

}

$.fn.exists = function () {
  return $(this).length > 0;
};

function ExternalLinkHandler () {
  var self = this;
  var hostname = document.location.hostname;

  this.matchInternalLink = [new RegExp("^http:\/\/(.*?)" + hostname)];

  this.addTargetAttribute = function (context) {

    context.find('a').filter('[href^="http://"], [href^="https://"]').each(function () {
      var anchor = $(this);
      var href = anchor.attr('href');
      if (!(href.indexOf(document.domain) > -1 || href.indexOf(':') === -1)) {
        anchor.attr('target', '_blank').addClass('external-link');
      }
    });
  };

}

function UIBindings () {

  this.bindTooltips = function () {
    if($('[data-toggle="tooltip"]').exists()) {

      $('[data-toggle="tooltip"]').tooltip();
    }
  };

  this.bindPopovers = function () {
    if($('[data-toggle="popover"]').exists()) {

      $('[data-toggle="popover"]').popover();
    }
  };


  this.bindMagnificPopup = function () {
    $('[data-magnific="gallery"]').magnificPopup({
      type: 'image',
      closeOnContentClick: false,
      closeBtnInside: true,
      mainClass: 'mfp-with-zoom mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        tCounter: '%curr% of %total%',
        preload: [0, 2] // Will preload 0 - before current, and 1 after the current image
      },
      zoom: {
        enabled: true,
        duration: 500 // don't foget to change the duration also in CSS

      }
    });

    $('[data-magnific="iframe"]').magnificPopup({
      type: 'iframe',
      closeOnContentClick: false,
      closeBtnInside: true,
      mainClass: 'mfp-with-zoom mfp-img-mobile',
      iframe: {
        patterns: {
          vimeo: {
              index: 'vimeo.com/', 
              id: function(url) {        
                  var m = url.match(/(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/);
                  if ( !m || !m[5] ) return null;
                  return m[5];
              },
              src: 'https://player.vimeo.com/video/%id%?autoplay=1'
          },
          aaayoutube1: {
            index: 'youtu.be/',
            id: function(url) {
              var m = url.match(/youtu\.be\/(.+?)/);
              if ( !m || !m[1] ) return '';
              return m[1];
            },
            src: 'https://www.youtube.com/embed/%id%?autoplay=1'
          }
      }
      }
    });

    $('[data-magnific-inline]').magnificPopup({
      type: 'inline',
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: 'auto',
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: 'my-mfp-zoom-in'
    });


    $(window).on('load', function(){
      var pathname =window.location.pathname
      if (pathname.indexOf('subscribe') !== -1) { 
        $.magnificPopup.open({
          items: {
            src: '#subscribe-dialog'
          },
          type: 'inline'
        });

        console.log('trigger click');
      }
    });
  };

  this.bindSlickCarousels = function () {
    $('[data-slick-carousel-default]').slick({
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true,
      rows: 0
    });

    var carousel = $('[data-slick]');
    if (carousel.length > 0) {
        carousel.slick({
            customPaging: function(slider, i) {
                return $('<span class="overlay px-sm d-flex"/>').html('<span class="d-block border border-primary border-2 w-100 dash mt-auto"/>');
            }
        });
    }
  };

  this.bindSharing = function () {
    $("[data-share-default]").each(function () {
      var shareHandler = new ShareHandler($(this));
      shareHandler.appendFacebook();
      shareHandler.appendTwitter();
      shareHandler.appendLinkedIn();
    });
  };

   this.bindSubmittingButtons = function () {
    $(document).on('submit', 'form', function () {
      var submitText = $(this).find('[data-submit-text]');
      var submittingText = $(this).find('[data-submitting-text]');
      var btn = submitText.closest('button');
      submittingText.removeClass('hide');
      submitText.hide();
      btn.prop('disabled', true);
    });

   
  };
}

function Selectize () {
  this.selectizeSimple = function ($forms) {

    function bindForm ($form) {
      var $loader = $('.loader');

      var fields = $form.find('[data-selectize]');
      var selectizeOpts = {

        plugins: ['remove_button'],
        maxItems: null,
        delimiter: '|',
        valueField: 'id',
        labelField: 'name',
        searchField: 'name',
        hideSelected: true,
        closeAfterSelect: true,
        onChange: function (value, el) {
        },
        onItemRemove: function (value) {
        },
        render: {
          option: function (item, escape) {
            return '<div>' +
              '<span class="title">' +
              '<span class="name">' + escape(item.name) + '</span>' +
              (item.count ? ' (' + escape(item.count) + ')' : '') +
              '</span>' +
              '</div>';
          }
        }
      };


      var singleSelectizeOpts = {
        //placeholder: "Select an option...",
        allowClear: true,
        //plugins: ['remove_button'],
        maxItems: 1,
        delimiter: '|',
        valueField: 'id',
        labelField: 'name',
        searchField: 'name',
        hideSelected: true,
        closeAfterSelect: true,
        onChange: function  (value, el) {
        },
        onItemRemove: function (value) {
        },
        render: {
          option: function (item, escape) {
            return '<div>' +
              '<span class="title">' +
              '<span class="name">' + escape(item.name) + '</span>' +
              (item.count ? ' (' + escape(item.count) + ')' : '') +
              '</span>' +
              '</div>';
          }
        }
      };

      fields.each(function () {
        var select = $(this);

        if (select.attr('multiple')) {
          select.selectize(selectizeOpts);
        } else {
          select.selectize(singleSelectizeOpts);
        }

        if (select.data('disabled')) {
          select[0].selectize.disable();
        }
      });

      var isLoading = false;
      $form.on('change', function (e) {
        if (isLoading) {
          return;
        }

        //console.log('$form.on change e',e );
        var $input = $(e.target);
        var match = $input.attr('name') ? $input.attr('name').match(/document_(\d)/) : null;

        // Clear the child inputs as the parent has changed
        if (match) {
          var num = parseInt(match[1], 10);
          for (var i = (num + 1); i <= 4; i++) {
            var $otherInput = $('[name="document_' + i + '"]');
            if ($otherInput.length) {
              $otherInput[0].selectize.clear();
            }
          }
        }

        if ($input.attr('name') && $input.attr('name').match(/theme/)) {
          $('[name="sub"]')[0].selectize.clear();
        }

        var locale = $('html').attr('lang');
        var section = $form.data('section-simple');
        var data = $(this).serialize();
        var url = window.location.pathname + '?' + data;
        console.log('$form.onChange...', url);
        $loader.addClass('loading');
        isLoading = true;

        $.ajax({
          url: url,
          success: function (resp) {
            var html = $(resp);
            //var html = $(resp.data);
            console.log("onSuccess", html);

            $('#main').html(html.html());

            $('#main').find('[data-mh]').matchHeight();

            bindForm($('#main').find('[data-section-simple]'));

            $loader.removeClass('loading');

            history.pushState({}, null, '?' + data);

            isLoading = false;

          }
        });
      });

      $form.find('[data-clear]').each(function () {
        $(this).on('click', function () {
          var name = $(this).data('clear');
          $('[name="' + name + '"]')[0].selectize.clear();
        });
      });

      if ($form.attr('load-now') !== undefined) {
        $loader.addClass('loading');

        $.ajax({
          url: window.location.href,
          success: function (resp) {
            var html = $(resp);
            $('#main').html(html.html());
            $('#main').find('[data-mh]').matchHeight();
            bindForm($('#main').find('[data-section-simple]'));
            $loader.removeClass('loading');
          }
        });
      }
    }

    $forms.each(function () {
      bindForm($(this));
    });
  };

}

function FormElements () {
  this.dropdownMenu = function () {
    $('[data-dropdown] > li').on('click', function (evt) {
      var el = $(this),
        dropdown = el.closest('.dropdown'),
        fieldName = el.data('fieldName'),
        field = dropdown.find('[name="' + fieldName + '"]'),
        label = dropdown.find('[data-label]');

      el.addClass('disabled');
      el.siblings().removeClass('disabled');

      toggleDropdownField({
        field: field,
        value: el.data('text') ? el.data('text').replace('_._', '') : el.data('value'),
        type: el.data('text') ? 'text' : 'hidden',
        placeholder: el.data('placeholder') ? el.data('placeholder') : ''
      });

      if (el.data('value') !== 'Other') {
        field.val(el.data('value'));
      }

      label.html(el.html());
    });

    function toggleDropdownField (options) {

      if (options.field.attr('type') === options.type) {
        return;
      }
      options.field.val($.type(options.value) !== 'number' ? options.value : '');
      options.field.attr('type', options.type);
      options.field.focus();
    }
  };
}

function Forms () {
  this.validate = function () {

    var forms = $('[data-form-validate]');

    if (!forms.exists()) {
      return this;
    }

    Parsley.addValidator('dropdown', {
      requirementType: 'integer',
      validate: function (value, requirement, instance) {
        var element = $(instance.$element),
          fieldName = element.data('fieldName'),
          fields = $('[name="' + fieldName + '"]').filter(function () {
            return $.trim(this.value) === ""; // remove the $.trim ifhitespace is counted as filled
          });

        return !fields.exists();


      }
    });

    Parsley.addValidator('honeypot', {
      requirementType: 'string',
      validate: function (value, requirement, instance) {
        return value === requirement;
      }
    });

    Parsley.on('form:submit', function (ParsleyForm) {

      var formElement = ParsleyForm.$element[0];

      if ($(formElement).is('subForm')) {
        var honeypot = $(formElement).find('#validationfield'),
          honeypotValue = $.trim(honeypot.val());

        if (honeypotValue.length > 0) {
          console.log(honeypotValue, 'Honey pot value');
          return false;
        } else {
          honeypot.remove();
          // updates field names
          var formFields = $(formElement).find('[data-name]');
          formFields.each(function (key, field) {
            field.name = $(field).data('name');
          });
        }
      }

    });

    forms.parsley({
      inputs: Parsley.options.inputs + ', [data-parsley-recaptcha], [data-parsley-honeypot], [data-parsley-dropdown]:visible',
      errorClass: 'has-error'
    });

    return this;

  };

  this.bindEvents = function(){
    var forms = $('form');

    forms.on('change', 'input[type="file"]', function (evt) {

        var input = $(this);
        var str = this.value;
        var strIndexOf = str.replace(/\\/g, '/');
        var filename = strIndexOf.split("/").pop();
        var parent = input.closest('[data-provides]');
        var imagePreview = parent.find('[data-update-image]');

        parent.addClass(this.value ? 'fileinput-exists' : 'fileinput-new').removeClass(this.value ? 'fileinput-new' : 'fileinput-exists');
        parent.find('.fileinput-filename').text(filename);
        parent.find('[data-update-hidden]').val(filename);
        parent.find('[data-update-checkbox]').prop("checked", true);
        parent.find('[data-update-remove]').val('');
        

        var $parsleyForm = $(this).closest('form').parsley();
        var group = $(this).closest('[data-parsley-group]').data('parsleyGroup');
        console.log(this.value, 'change', group);

        if (group) {
            $parsleyForm.validate({group: group, force: true});
        }

    });

    forms.on('click', '[data-dismiss]', function (evt) {
        var el = $(this);
        var parentId = el.data('dismiss');
        var parent = el.closest('[data-provides]');
        var group = parent.find('input[type="file"]').closest('[data-parsley-group]').data('parsleyGroup');
        parent.removeClass('fileinput-exists').addClass('fileinput-new');
        parent.find('input[type="file"]').val('').trigger('change');
        
        console.log(parent.find( 'file remove', 'input[type="file"]').val());
        return false;
    });
  }
}

$.fn.customAffix = function (options) {
  var that = $(this);
  if (!that.length) {
    return;
  }
  var settings = $.extend({
      scrollTopMinSelector: that.parent(),
      scrollTopMaxSelector: '#footer',
      offset: 20
    }, options),
    _window = $(window),
    positionElement = function () {

      var element, parentElement,
        scrollTopMaxElement,
        scrollTopMinElement,
        scrollTop,
        elementHeight,
        elementTop,
        documentHeight, scrollTopMax, scrollBottomMax, scrollDifference, top;

      element = $(this);
      parentElement = that.parent();
      scrollTopMaxElement = $(settings.scrollTopMaxSelector);
      scrollTopMinElement = $(settings.scrollTopMinSelector);
      scrollTop = _window.scrollTop();
      elementHeight = element.outerHeight();
      elementTop = scrollTopMinElement.offset().top;
      documentHeight = $(document).outerHeight();
      scrollTopMax = scrollTopMaxElement.outerHeight();
      scrollBottomMax = documentHeight - ( elementHeight + scrollTopMax);
      scrollDifference = scrollBottomMax - scrollTop;

      element.removeClass('sticky static');


      if (scrollTop < elementTop) {
        element.removeAttr('style').addClass('static');
      } else if (scrollTop < scrollBottomMax) {
        element.addClass('sticky').css({'top': settings.offset, 'width': parentElement.width()});
      } else {
        element.addClass('sticky').css({'top': scrollDifference, 'width': parentElement.width()});
      }
    };

  return this.each(function () {
    _window.on('scroll scrollstop resize', $.proxy(positionElement, this));
    $.proxy(positionElement, this)();
  });
};

var campaign = (function (c, d, $) {

  var form,
      form_id,
      config,
      successMessage;

  c.init = function () {

    var body = $('body');
    form = $('[data-form-createsend]');
    
    var successMessage = form.find('.success');
    var errorMessage = form.find('.error');

    successMessage.hide();
    errorMessage.hide();

    // On form submit.
    form.submit(function (evt) {
      var successMessage = $(this).find('.success');
      var errorMessage = $(this).find('.success');
      form_id = form.attr('data-id');
      var data = $(this).serialize();
      // Disable default form submit.
      evt.preventDefault();


      // Get e-mail value.
      var email = $('input[type=email]', form).val();

      // Build request data for tokenRequest.
      var request_data = "email=" + encodeURIComponent(email) + "&data=" + form_id;

      // Prepare tokenRequest.
      var tokenRequest = new XMLHttpRequest();
      tokenRequest.open('POST', 'https://createsend.com//t/getsecuresubscribelink', true);
      tokenRequest.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      tokenRequest.send(request_data);

      // Ready state.
      tokenRequest.onreadystatechange = function() {
        if (this.readyState === 4) {
          if (this.status === 200) {
            // Having token and new submit url we can create new request to subscribe a user.
            var subscribeRequest = new XMLHttpRequest();
            subscribeRequest.open('POST', this.responseText, true);
            subscribeRequest.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            subscribeRequest.send(data);
            // On ready state call response function.
            subscribeRequest.onreadystatechange = function() {
              c.response(subscribeRequest, successMessage, errorMessage);
            }
          } else {
            c.response(tokenRequest, successMessage, errorMessage);
          }
        }
      }
    });
  };

  // Handle ajax response.
  c.response = function(request, successMessage, errorMessage) {
    if (request.readyState === 4) {
      if (request.status === 200) {
        // redirect to thank you page
        successMessage.show('slow');
      } else {
        errorMessage.show('slow');
      }

      var form = successMessage.closest('form');
      var submitText = form.find('[data-submit-text]');
      var submittingText = form.find('[data-submitting-text]');
      var btn = form.find('button');
      submittingText.addClass('hide');
      submitText.show();
      btn.prop('disabled', false);

      setTimeout(function(){
        successMessage.hide('slow');
        errorMessage.hide('slow');
      },3000);
    }
  };

  return c;
  
}(campaign || {}, {}, jQuery));

var addToCalendar = (function(c, $) {
  c.init = function() {
    if(!$('[data-addtocal]').exists()) { return }
    $('[data-addtocal]').AddToCal({
      /* ical and vcal require an ics or vcs file to be served.
       * Since we don't have a server for this demo, these features are disabled.
       * As a result the 30boxes, iCal and vCalendar menu links will not appear
       */
      icalEnabled:true,
      vcalEnabled: false,
      webcalEnabled: false,
      getEventDetails: function( element ) {
        var event = element.data('addtocal');
        var start = event.start ? new Date(event.start) : new Date();
        var end = event.end ? new Date(event.end) : new Date();
        var title = event.title ? event.title : element.text();
        var details = event.details ? event.details : element.html();
        var ical = event.ics ? event.ics : null;
        var eventUrl = event.url ? event.url : null;
  
        // return the required event structure
        return {
          webcalurl: null,
          icalurl: ical,
          vcalurl: null,
          start: start,
          end: end,
          title: title,
          details: details,
          location: null,
          url: eventUrl
        };
      },
    });
  }

  return c;

})(addToCalendar || {}, jQuery);

// Covid-19 hub page scripts
$(function () {
  if ($('#covid19-monitor').length === 0) {
    return;
  }

  $('#covid19-nav').selectize({
    create: true,
  });
  $('#covid-show-field').selectize({
    create: true,
  });
  $('#covid19-nav').on('change', function () {
    var val = $(this).val();

    if (val) {
      $('html').animate({scrollTop: $('#' +val).offset().top}, 100);
      $(this).val('');
    }

  });

  $("#covid-show-field").on('change', function () {
    let val = $(this).val();

    if (val) {
      let elements = $('[data-covid-item]');
      let showElements = elements.filter('[data-id="'+val+'"]');

      elements.hide();
      showElements.filter('[data-id="'+val+'"]').show();

      $('#covid19-monitor').find('.collapse').removeClass('in');
      $('#covid19-monitor').find('.card').hide();

      showElements.each(function () {
        $(this).closest('.collapse').addClass('in');
        $(this).closest('.card').show();
      });

    } else {
      let elements = $('[data-covid-item]');

      elements.show();
      $('#covid19-monitor').find('.card').show();

    }
  });



  // (new Selectize()).selectizeSimple($('form').filter('[data-section-simple]'));



  // var floatingNav = $('#floating-nav');
  // var originalTopOffset = floatingNav.offset().top;
  //
  // function updateOffset() {
  //   var scrollTop = $('html').scrollTop();
  //   var topOffset = floatingNav.offset().top;
  //   console.log('scrollTop', scrollTop, 'topOffset', topOffset);
  //
  //   if (scrollTop > originalTopOffset) {
  //     floatingNav.css('position', 'fixed');
  //     floatingNav.css('top', '0');
  //
  //     // floatingNav.css('position', 'relative');
  //     // floatingNav.css('top', scrollTop - originalTopOffset);
  //   } else {
  //     floatingNav.css('position', 'static');
  //   }
  // }
  //
  //
  // var offsets = {};
  //
  // $('[data-country-nav]').each(function () {
  //   offsets[$(this).attr('data-country-nav')] = $(this).offset().top;
  // })
  //
  // updateOffset();
  // updateActiveNav();
  ///**/
  // function updateActiveNav() {
  //   var scrollTop = $('html').scrollTop();
  //   var currentId = null;
  //   var floatingNavHeight = floatingNav.height();
  //
  //   var headings = {};
  //
  //   $('[data-country-block]').each(function () {
  //     headings[$(this).attr('id')] = $(this).offset().top;
  //   });
  //
  //   $.each(headings, function (id, topOffset) {
  //     if (topOffset-20 < scrollTop) {
  //       currentId = id;
  //       console.log('updateActiveNav', topOffset, scrollTop, currentId)
  //     }
  //   });
  //
  //
  //   var activeCountryNav = $('[data-country-nav="' + currentId + '"]');
  //
  //   $('[data-country-nav]').removeClass('active');
  //
  //   if (activeCountryNav.length)  {
  //
  //     activeCountryNav.addClass('active');
  //
  //     var relativeOffset = offsets[currentId] - originalTopOffset;
  //
  //     if (floatingNav.scrollTop() > relativeOffset) {
  //       floatingNav.scrollTop(relativeOffset - 20);
  //     }
  //     if (floatingNav.scrollTop() + floatingNavHeight < relativeOffset) {
  //       floatingNav.scrollTop(relativeOffset - 20);
  //     }
  //
  //     console.log('floatingNav.scrollTop()', floatingNav.scrollTop(), 'relativeOffset', relativeOffset);
  //   }
  // }

  // $(window).scroll(function () {
  //   updateOffset();
  //   updateActiveNav();
  // });

});