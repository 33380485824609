$(() => {
  const country = window.countryCode;
  const year_start = 2009;

  if ($('#country-profile').length === 0) {
    return
  }

  $.ajax({
    url: `/_debt-frontend.php/country-profiles?country=${country}&year_start=${year_start}`,
    success: (data) => {
      const rowsByYear = _.groupBy(data, 'year');
      const rowsByKey = _.groupBy(data, 'concept');
      const table = $('#country-profile tbody');

      const headerRow = $('<tr>').append($('<td>').text(""));

      Object.keys(rowsByYear).forEach((year, i) => {
        headerRow.append($(`<td class="text-right year ${i % 2 === 0 ? 'alt' : ''}">`).text(year));
      });

      table.append(headerRow);

      Object.keys(rowsByKey).forEach((key) => {
        const row = $('<tr>').append($('<td class="" style="font-weight: bold;">').text(rowsByKey[key][0].label));

        Object.keys(rowsByYear).forEach((year, i) => {
          const dataRow = _.keyBy(rowsByYear[year], 'concept')[key];
          const cell = $(`<td class="text-right ${i % 2 === 0 ? 'alt' : ''}">`);

          if (dataRow) {
            const value = new Intl.NumberFormat('en-US', {
              style: 'decimal',
              maximumFractionDigits: 2
            }).format(dataRow.value);

            cell.text(dataRow.prefix ? `${dataRow.prefix} ${value}` : dataRow.suffix ? `${value}${dataRow.suffix}` : value);
          }

          row.append(cell);
        });

        table.append(row);
      });
    }
  });



  $.ajax({
    url: `/_debt-frontend.php/country-matrix?country=${country}&year_start=${year_start}`,
    success: (jsonData) => {

      console.log('jsonData', jsonData);

// Get unique years
      const years = _.uniq(jsonData.map(d => d.year)).sort();
      const minYear = Math.min(...years);
      const maxYear = Math.max(...years);

// Generate all years from 2010 to 2025
      const allYears = _.range(2010, 2026);

// Group by Phase
      const groupedByPhase = _.groupBy(jsonData, 'phase');

      let table = '<tr><td style="background: #444; color: white; vertical-align: middle; font-weight: bold">PHASE</td><td class="alt" style="font-weight: bold;">TYPE</td>';
      table += allYears.map((year, i) => `<td class="${i % 2 == 0 ? '' : 'alt'}" style="font-weight: bold;">${year}</td>`).join('');
      table += '</tr>';

      _.forEach(groupedByPhase, (documents, phase) => {
        // Group documents under each phase by document type
        const groupedByDocumentType = _.groupBy(documents, 'document_type');
        const phaseRowSpan = Object.keys(groupedByDocumentType).length;
        let firstRow = true;

        _.forEach(groupedByDocumentType, (entries, documentType) => {
          let row = '<tr>';

          if (firstRow) {
            row += `<td style="background: #444; color: white; vertical-align: middle; font-weight: bold" rowspan="${phaseRowSpan}">${phase}</td>`;
            firstRow = false;
          }

          row += `<td style="background: #e3e3e3; font-weight: bold">${documentType}</td>`;

          allYears.forEach((year, i) => {
            const entry = _.find(entries, { year });
            const map = {
              0: '&#10008;',
              2: '&#8505;',
              1: '&#10004;',
            }

            const bgMap = {
              0: '#d55b5b',
              2: '#46a4b9',
              1: '#b0b777',
            }

            row += `<td style="color: white; background: ${entry ? bgMap[entry.value] : ''}; vertical-align: middle; text-align: center" class="${i % 2 == 0 ? '' : 'alt'}"><div title="${entry ? entry.label : ''}">${entry ? map[entry.value] : ''}</div></td>`;
          });

          row += '</tr>';
          table += row;
        });
      });

      table += '';

      $('#country-matrix tbody').html(table);

    }
  });



});