/* globals angular */
/* globals _ */
/*jshint strict:false */

angular.module('debtMonitorApp', ['ui.select', 'ngSanitize', 'ui.bootstrap'])
  .config(['$locationProvider', function ($locationProvider) {
    $locationProvider.hashPrefix('');
  }])
  .run(['$rootScope', '$http', function ($rootScope, $http) {
    if ($rootScope.glossary === undefined) {
      $rootScope.glossary = null;
      $http.get('/_debt-frontend.php/data-v2/glossary').then(function (resp) {
        $rootScope.glossary = resp.data;
        $rootScope.glossaryByTerm = {};
        $rootScope.glossaryByTermAlt = {};

        angular.forEach(resp.data, function (item) {
          $rootScope.glossaryByTerm[item.term.toLowerCase()] = item;
          $rootScope.glossaryByTermAlt[item.alt_term.toLowerCase()] = item;
        });
        console.log('$rootScope.glossaryByTerm', $rootScope.glossaryByTerm);
      });
    }
  }])
  .controller('DebtMonitorBlankController', ["$scope", "$http", "$sce", "$location", "$q", function ($scope, $http, $sce, $location, $q) {
  }])
  .controller('DebtMonitorIndicatorsController', ["$scope", "$http", "$location", "$q", function DebtMonitorIndicatorsController($scope, $http, $location, $q) {
    var countriesPromise = $http.get('/_debt-frontend.php/data-v2/countries?country=' + window.countryCode);
    var ctrl = this;

    ctrl.loadData = loadData;

    ctrl.currency = "local";

    countriesPromise.then(function (resp) {
      ctrl.countries = resp.data;

      _.forEach(ctrl.countries, function (c, i) {
        if (c && c.value === window.countryCode) {
          ctrl.countries.splice(i, 1);
        }
      });

      if (!ctrl.country) {
        //ctrl.year = ctrl.countries[ctrl.years.length - 1];
        //loadData();
      }
    });

    ctrl.renderGraphs = function () {
      if (ctrl.compareCountry) {
        ctrl.currency = 'usd';
      }
      renderGraphs(ctrl.currency, ctrl.compareCountry);
    };

    function loadData() {
      //var dataPromise = $http.get('/_debt-frontend.php/data-v2/debt?country=' + window.countryCode + '&year=' + ctrl.year.value);
      //dataPromise.then(function (resp) {
      //  ctrl.data = resp.data;
      //});
    }

  }])
  .controller('DebtMonitorDataTableController', ["$scope", "$http", "$location", "$q", function DebtMonitorDataTableController($scope, $http, $location, $q) {

    var yearsPromise = $http.get('/_debt-frontend.php/data-v2/years?country=' + window.countryCode);
    var ctrl = this;
    ctrl.loadData = loadData;

    yearsPromise.then(function (resp) {
      // ctrl.years = resp.data;
      ctrl.years = resp.data.filter((a) => a.hasData);

      if (!ctrl.year) {
        ctrl.year = ctrl.years[ctrl.years.length - 1];
        loadData();
      }
    });


    function loadData() {
      var dataPromise = $http.get('/_debt-frontend.php/data-v2/debt?country=' + window.countryCode + '&year=' + ctrl.year.value + '&lang=' + window.lang);
      dataPromise.then(function (resp) {
        ctrl.data = resp.data.data;
        ctrl.country = resp.data.country;
      });
    }

  }])

  .controller('DebtMonitorController', ["$scope", "$http", "$location", "$q", function ($scope, $http, $location, $q) {
    var ctrl = this;


    var query = $location.search();
    var _query = query.query ? JSON.parse(query.query) : {};


    ctrl.xAxisOptions = [
      {value: 'year', label: 'Year'},
      {value: 'country', label: 'Country'}
    ];

    ctrl.dataTypeOptions = [
      {value: '%', label: 'Percent (%)'},
      {value: 'local', label: 'Local currency'},
      {value: 'usd', label: 'USD (converted)'},
    ];

    ctrl.params = {
      yCountries: [],
    };
    ctrl.params.xAxisBy = 'year';
    ctrl.params.currency = _query.currency || 'usd';
    ctrl.params.chartType = 'bar';
    ctrl.params.indicatorType = 'P1';

    $scope.keysLoading = false;
    $scope.yearsLoading = false;
    $scope.countriesLoading = false;
    ctrl.allowCountryComparison = false;


    if (!(_query.yCountries && _query.yCountries.length) && window.countryCode) {
      _query.yCountries = [window.countryCode];
      $location.search({query: JSON.stringify(_query)});
    }

    function loadYears() {
      console.log('loadYears...', angular.copy(ctrl.params));

      var promise = $http.get('/_debt-frontend.php/data-v2/years');
      $scope.yearsLoading = true;

      promise.then(function (resp) {
        let years = resp.data;
        ctrl.years = [];

        _.forEach(years, function (year) {
          // Only display years that apply to all
          // let intersection = _.intersection(year.countries, ctrl.params.yCountries.map(c => c.value));
          // if (intersection.length === ctrl.params.yCountries.length && ctrl.params.yCountries.length > 0) {
          //   ctrl.years.push(year);
          // }

          ctrl.years.push(year);

        });

        ctrl.params.yYears = _.intersectionBy(ctrl.years, ctrl.params.yYears, 'value');

        console.log('loadYears', ctrl.years);

        $scope.yearsLoading = false;

      });

      return promise;
    }

    function loadCountries() {
      var promise = $http.get('/_debt-frontend.php/data-v2/countries?lang=' + window.lang);
      $scope.countriesLoading = true;
      promise.then(function (resp) {
        ctrl.countries = resp.data;
        $scope.countriesLoading = false;
      });
      return promise;
    }

    ctrl.loadKeysIndex = 0;

    function loadKeys(fn) {
      console.log('loadKeys...', angular.copy(ctrl.params));

      var keysPromise = $http.get(
        '/_debt-frontend.php/data-v2/keys?country=' + getSelectedCountries().join(',')
        + '&currency=' + ctrl.params.currency
        + '&type=' + ctrl.params.indicatorType
        + '&lang=' + window.lang
        + '&years=' + ctrl.params.xYears.map(y => y.value).join(',')
      );
      $scope.keysLoading = true;

      keysPromise.then(function (resp) {
        ctrl.dataKeys = resp.data;

        ctrl.dataKeysIndex = [];

        function flatten(item) {
          ctrl.dataKeysIndex.push(item);
          _.forEach(item.children, function (child) {
            flatten(child);
          });
        }

        _.forEach(ctrl.dataKeys, function (child) {
          flatten(child);
        });

        $scope.keysLoading = false;

        ctrl.loadKeysIndex++;

      });

      return keysPromise;
    }

    $scope.$watch('ctrl.params.yCountries', function () {
      if (ctrl.params.yCountries && ctrl.params.yCountries.length > 1) {
        ctrl.params.currency = 'usd';
        ctrl.disableLocalCurrency = true;
      } else {
        ctrl.disableLocalCurrency = false;
      }
    }, true);

    $scope.$watch('ctrl.params.currency', function () {
      // if (ctrl.params.currency === 'local') {
      //   ctrl.allowCountryComparison = false;
      //   // ctrl.params.yCountries = [];
      // } else {
      //   ctrl.allowCountryComparison = true;
      // }

      // if (ctrl.params.yCountries && ctrl.params.yCountries.length > 1) {
      //   ctrl.params.yCountries = ctrl.params.yCountries.slice(0, 1);
      // }

      // ctrl.reloadKeys(function () {
      //   $scope.updateChart();
      // });
    });

    $scope.$watch('ctrl.params.yCountries', function () {

      $http.get('/_debt-frontend.php/data-v2/notes?country=' + getSelectedCountries().join(',')).then(function (resp) {
        ctrl.notes = resp.data;
      });

    });

    $scope.$watch('ctrl.params.xYears', function () {
      if (ctrl.params.xYears) {
        ctrl.params.xYears.sort(function (a, b) {
          return a.value < b.value ? -1 : 1;
        });
      }
    });

    $scope.currencyChange = function () {

      var query = getQueryFromState();

      updateUrlQuery(query);

      loadKeys(() => updateChart());

    };


    // ctrl.reloadKeys = function (fn) {
    //   console.log('ctrl.reloadKeys');
    //   loadKeys(fn);
    // };

    function updateStateFromUrl() {
      console.log('updateStateFromUrl...', angular.copy(ctrl.params));

      var _query = {};

      if ($location.search().query) {
        _query = JSON.parse($location.search().query);
      }

      console.log('_query', _query);

      _.forEach(['yYears', 'xYears', 'yCountries', 'xCountries', 'ySeries'], function (remap) {
        ctrl.params[remap] = _.map(_query[remap], v => ({value: v}));
      });

      console.log('ctrl.params[remap]', ctrl.params);

      if (_query.currency) {
        ctrl.params.currency = _query.currency;
      }

      ctrl.params.yYears = _.intersectionBy(ctrl.years, ctrl.params.yYears, 'value');
      ctrl.params.xYears = _.intersectionBy(ctrl.years, ctrl.params.xYears, 'value');
      ctrl.params.yCountries = _.intersectionBy(ctrl.countries, ctrl.params.yCountries, 'value');
      ctrl.params.xCountries = _.intersectionBy(ctrl.countries, ctrl.params.xCountries, 'value');
      ctrl.params.ySeries = _.intersectionBy(ctrl.dataKeysIndex, ctrl.params.ySeries, 'value');

      // console.log('ctrl.dataKeysIndex, ctrl.params.ySeries', ctrl.dataKeysIndex, ctrl.params.ySeries);

      ctrl.params.chartType = _query.type || 'bar';
      ctrl.params.indicatorType = _query.indicatorType || 'P1';

      _.forEach(ctrl.params.xYears, item => item.$selected = true);
      _.forEach(ctrl.params.xCountries, item => item.$selected = true);
      _.forEach(ctrl.params.yCountries, item => item.$selected = true);

      _.forEach(ctrl.params.ySeries, item => {
        if (!item.is_disabled) {
          item.$selected = true
        } else {
          item.$selected = false
        }
      });

      console.log('ctrl.params', ctrl.params, '_query', _query);

      // if (ctrl.params.xYears === undefined || ctrl.params.xYears.length == 0) {
      //   ctrl.params.xYears = [ctrl.years[ctrl.years.length - 1]];
      // }


    }

    function getQueryFromState() {
      var query = {};
      query.xAxisBy = ctrl.params.xAxisBy;
      query.yYears = _.map(ctrl.params.yYears, 'value');
      query.xYears = _.map(ctrl.params.xYears, 'value');
      query.yCountries = _.map(ctrl.params.yCountries, 'value');
      query.xCountries = _.map(ctrl.params.xCountries, 'value');
      query.ySeries = _.map(_.filter(ctrl.params.ySeries, '$selected'), 'value');
      query.type = ctrl.params.chartType;
      query.indicatorType = ctrl.params.indicatorType;
      query.currency = ctrl.params.currency;

      query.yCountries = _.uniq(query.yCountries);

      console.log('getQueryFromState...', ctrl.params, query);

      return query;
    }

    function updateUrlQuery(query) {
      console.log('updateUrlQuery...', query);
      $location.search({query: JSON.stringify(query)});

    }

    $scope.initialLoading = true;

    loadCountries().then(() => {
      updateStateFromUrl();
      loadYears().then(function (resps) {
        updateStateFromUrl();
        loadKeys().then(function () {
          updateStateFromUrl();

          $scope.updateChart();

          $scope.initialLoading = false;
          $scope.firstLoadComplete = true;
        });

      });

    });


    //$scope.$watch('ctrl.params.xAxisBy', function () {
    //  if (ctrl.params.xAxisBy == 'year') {
    //    ctrl.params.xYears = ctrl.years;
    //  }
    //});

    function getSelectedCountries() {
      var yCountries = angular.copy(ctrl.params.yCountries);
      // if (window.countryCode) {
      //   yCountries = yCountries || [];
      //   yCountries.push({value: window.countryCode});
      // }
      return _.uniq(_.map(yCountries, 'value'));
    }

    $scope.onYearChange = onYearChange;

    function onYearChange() {
      updateChart();
      loadKeys();
    }

    $scope.onCountryChange = onCountryChange;

    function onCountryChange() {

      console.log('onCountryChange...', angular.copy(ctrl.params));

      // loadYears().then(() => loadKeys());

      // console.log('onCountryChange...', ctrl.params.yCountries, ctrl.years, ctrl.params.xYears);

      updateChart();
    }

    ctrl.selectAllCountries = function () {
      ctrl.params.yCountries.splice(0, ctrl.params.yCountries.length)

      _.forEach(ctrl.countries, function (country) {
        country.$selected = true;
        ctrl.params.yCountries.push(country);
      });

      onCountryChange();
    }

    ctrl.selectAllYears = function () {
      ctrl.params.xYears.splice(0, ctrl.params.xYears.length)
      _.forEach(ctrl.years, function (year) {
        year.$selected = true;
        ctrl.params.xYears.push(year);
      });
      onYearChange();
    }

    ctrl.clearAllCountries = function () {
      ctrl.params.yCountries.splice(0, ctrl.params.yCountries.length);
      _.forEach(ctrl.countries, function (country) {
        country.$selected = false;
      });
      onCountryChange();
    }

    ctrl.clearAllYears = function () {
      ctrl.params.xYears.splice(0, ctrl.params.xYears.length);
      _.forEach(ctrl.years, function (year) {
        year.$selected = false;
      });
      onYearChange();
    }

    $scope.updateChart = updateChart;

    var updateThrottle = null;

    function updateChart() {
      console.log('updateChart...', angular.copy(ctrl.params));

      clearTimeout(updateThrottle);

      //DEBUG
      //var query = {"xAxisBy":"year","yYears":[],"xYears":["2015","2016","2017","2018"],"yCountries":["BW","ZA"],"xCountries":[],"ySeries":["domestic-debt-marketable-short-term-short-term-debt-total"],"type":"bar","currency":"local"};
      //
      //renderGraph(document.querySelector("#chart"), query);
      //return;
      console.log('update chart...');

      ctrl.hideChart = true;

      var query = getQueryFromState();

      updateUrlQuery(query);

      if (ctrl.params.xAxisBy == 'year') {
        if (!(ctrl.params.xYears && ctrl.params.xYears.length && ctrl.params.ySeries && ctrl.params.ySeries.length && ctrl.params.yCountries && ctrl.params.yCountries.length)) {
          return;
        }
      }

      if (ctrl.params.xAxisBy == 'country') {
        if (!(ctrl.params.xYears && ctrl.params.ySeries && ctrl.params.yYears)) {
          return;
        }
      }
      console.log('update chart2...');
      ctrl.hideChart = false;


      console.log('update chart with', query);

      renderGraph(document.querySelector("#chart"), query);
      renderTable(document.querySelector("#debt-table"), query);


      ctrl.jsonConfig = JSON.stringify(query);

    }

    //$scope.$watch('ctrl.params', function () {
    //
    //  //DEBUG
    //  //var query = {"xAxisBy":"year","yYears":[],"xYears":["2015","2016","2017","2018"],"yCountries":["BW","ZA"],"xCountries":[],"ySeries":["domestic-debt-marketable-short-term-short-term-debt-total"],"type":"bar","currency":"local"};
    //  //
    //  //renderGraph(document.querySelector("#chart"), query);
    //  //return;
    //  //
    //
    //  if (ctrl.params.xAxisBy == 'year') {
    //    if (!(ctrl.params.xYears && ctrl.params.ySeries && ctrl.params.yCountries)) {
    //      return;
    //    }
    //  }
    //
    //  if (ctrl.params.xAxisBy == 'country') {
    //    if (!(ctrl.params.xYears && ctrl.params.ySeries && ctrl.params.yYears)) {
    //      return;
    //    }
    //  }
    //
    //  var query = {};
    //  query.xAxisBy = ctrl.params.xAxisBy;
    //  query.yYears = _.map(ctrl.params.yYears, 'value');
    //  query.xYears = _.map(ctrl.params.xYears, 'value');
    //  query.yCountries = _.map(ctrl.params.yCountries, 'value');
    //  query.xCountries = _.map(ctrl.params.xCountries, 'value');
    //  query.ySeries = _.map(ctrl.params.ySeries, 'value');
    //  query.type = ctrl.params.chartType;
    //  query.currency = ctrl.params.currency;
    //
    //  renderGraph(document.querySelector("#chart"), query);
    //
    //  ctrl.jsonConfig = JSON.stringify(query);
    //
    //}, true);


  }])
  .controller('DebtMonitorSurveyController', ["$scope", "$http", "$sce", "$location", "$q", function ($scope, $http, $sce, $location, $q) {
    var ctrl = this;
    ctrl.query = $location.search();
    ctrl.country = window.country;
    ctrl.changeCountry = changeCountry;

    $scope.showQuestion = function (row) {
      $('#question-modal').modal();

      ctrl.focusQuestion = row;
      ctrl.focusQuestionRows = _.filter(ctrl.all, {label: row.label});
    };

    var countriesPromise = $http.get('/_debt-frontend.php/data/countries');

    countriesPromise.then(function (resp) {
      ctrl.countries = resp.data;
      ctrl.country = _.find(ctrl.countries, {value: window.countryCode || 'ZA'});
    });

    var surveyPromise = $http.get('/_debt-frontend.php/data/survey-all?lang=' + window.lang);

    surveyPromise.then(function (resp) {
      ctrl.all = resp.data;
    });

    $q.all([countriesPromise, surveyPromise]).then(function () {
      changeCountry();
    });

    ctrl.anyValuePresent = function (labels) {
      for (var i = 0; i < labels.length; i++) {
        if (ctrl.byLabel[labels[i]] && ctrl.byLabel[labels[i]].value !== 'N/A') {
          console.log('ctrl.anyValuePresent', ctrl.byLabel[labels[i]]);
          return true;
        }
      }
      return false;
    }


    function changeCountry() {
      var rows = _.filter(ctrl.all, {country: window.countryCode || (ctrl.country ? ctrl.country.value : 'ZA')});

      ctrl.rows = rows;
      ctrl.byId = _.keyBy(ctrl.rows, 'question_id');
      ctrl.byLabel = _.keyBy(ctrl.rows, 'label');
      ctrl.groups = _.groupBy(ctrl.rows, 'section');
      ctrl.answersByQuestion = _.groupBy(ctrl.all, 'label');


      _.forEach(ctrl.rows, function (row) {
        row.value_bool = null;
        if (row.value === 'Yes') {
          row.value_bool = true;
        }
        if (row.value === 'No') {
          row.value_bool = false;
        }
        if (row.value === 'Oui') {
          row.value_bool = true;
        }
        if (row.value === 'Não') {
          row.value_bool = false;
        }
        if (row.value === 'Sim') {
          row.value_bool = true;
        }
        if (row.value === 'Non') {
          row.value_bool = false;
        }

        row.value_int = parseInt(row.value);

        row.statement = row.value_bool ? row.statement_true : row.statement_false;

        if (row.value === 'na') {
          row.value = 'N/A';
        }

        if (row.value === 'n/a') {
          row.value = 'N/A';
        }

        row.list = [];
        if (row.value) {

          row.list = row.value.split('\n');

          row.list = _.map(row.list, function (item) {
            item = _.trim(item);

            var m = item.match(/\((.+?)\)/g);

            return $sce.trustAsHtml(item);
          });

          row.value = row.value.replace(/•/g, '<br/>•');
          row.value = row.value.replace(/•/g, '<i class="fa fa-chevron-right"></i>');
        }


      });
    }


  }]);


function renderGraph(chartElement, query) {

  if (!(query && Object.keys(query).length)) {
    $(chartElement).html('No data...');
    return;
  }

  //keyValues.forEach(function (seriesKey) {
  //  countryValues.forEach(function (countryKey) {
  //  });
  //});

  query.lang = window.lang;

  fetch('/_debt-frontend.php/data-v2/series?json=' + encodeURIComponent(JSON.stringify(query))).then(function (resp) {
    return resp.json();
  }).then(function (_series) {
    console.log('series', _series);

    var options = {};

    if (!(query && _series && _series.length && _series[0].data)) {
      console.log('no data 1');
      $(chartElement).html('No data...<!-- 1 -->');
      if (query.hideIfEmpty) {
        $(chartElement).closest('[debt-graph]').hide();
      }
      return;
    }

    if (_series[0].data && _series[0].data.length && _series[0].data[0].y === null && query.type == 'radialBar') {
      console.log('no data 2');
      $(chartElement).html("No data...<!-- 2 -->");
      if (query.hideIfEmpty) {
        $(chartElement).closest('[debt-graph]').hide();
      }
      return;
    }

    var currency = _series[0].currency;
    var currencyIndex = {};


    _.forEach(_series, function (series, i) {
      if (series.currency) {
        currencyIndex[series.currency] = true;
      }
      if (query.types && query.types[i]) {
        series.type = query.types[i];

        options.stroke = options.stroke || {};
        options.stroke.width = options.stroke.width || [];
        if (series.type === 'bar') {
          options.stroke.width.push(2);
        } else {
          options.stroke.width.push(4);
        }

        options.stroke.width = [2, 2];

      }
      //_.forEach(series.data, function (data) {
      //
      //  if (data.y) {
      //    data.y = new Currency(data.y, 'bar');
      //    //data.y.currency = 'foo';
      //  } else {
      //    data.y = null;
      //  }
      //  //data.y = data.y;
      //});
    });

    if (Object.keys(currencyIndex).length > 1) {
      throw new Error("Refusing to create a graph with more than 1 currency");
    }

    var labels = null;
    var series = null;


    if (query.labels) {
      labels = query.labels;
    }

    if (query.type == 'numberOnly') {
      $(chartElement).html(_series[0].data[0].y);
      return;
    }


    if (query.type == 'currencyValue') {
      var div1 = $('<div></div>');
      var div2 = $('<div></div>');
      div1.html(_series[0].currency + '&nbsp;' + numeral(_series[0].data[0].y).format('#,#.#') + '&nbsp;Mil');

      if (labels && labels[0]) {
        div2.html(labels[0]);
      }

      div1.addClass('currency-value');
      div2.addClass('currency-value-date');

      $(chartElement).html('');
      $(chartElement).append(div1);
      $(chartElement).append(div2);
      return;
    }

    if (query.type == 'radialBar') {
      series = [_series[0].data[0].y];
      labels = [_series[0].data[0].x];
    } else {
      series = _series;
    }

    $.extend(options, {
      colors: [
        "#006885",
        "#7f8943",
        "#55adc1",
        "#a6b580",
        "#f5852a",
        "#1d7aa1",
        "#5cb748"
      ],
      chart: {
        height: query.height || 380,
        width: "100%",
        type: query.type,
        //stacked: true,
        fontFamily: 'Calibri, Arial, sans-serif',
        toolbar: {
          show: false
        }
      },
      markers: {
        size: 6
      },
      series: series,
      //labels: labels,
      dataLabels: {
        enabled: false
      },
      legend: {
        show: query.type !== 'pie' && query.style === "simple" && _series.length <= 1 ? false : true,
        showForSingleSeries: true,
        //horizontalAlign: 'left',
        itemMargin: {
          horizontal: 2,
          vertical: 5
        }
      },
      tooltip: {
        y: {
          formatter: function (value, obj) {// obj: { series, seriesIndex, dataPointIndex, w }

            // return numeral(value).format('#,#.###');
            // return numeral(value).format('#,#');

            if (currency == '%') {
              return numeral(value).format('#.###');
            } else {
              return numeral(value).format('#,#');
            }

            //console.log('tooltip y formatter', value, obj);
            // return series[seriesIndex].data[dataPointIndex].y;
          },
          title: {
            formatter: function (seriesName) {
              return seriesName;
            }
          }
        }
      },
      yaxis: {
        title: {
          text: _series && _series[0] ? _series[0].suffix : ''
        },
        labels: {
          formatter: function (value) {
            if (currency == '%') {
              return numeral(value).format('#.#');
            }


            return value ? numeral(value).format('#,#') : value;
          }
        },
        // crosshairs: {
        //   show: query.style === "simple" ? false : true
        // },
        // axisTicks: {
        //   show: query.style === "simple" ? false : true
        // },
        // axisBorder: {
        //   show: query.style === "simple" ? false : true
        // },
        // show: false
      },

      xaxis: {
        labels: {
          rotate: 0,
        }
        // crosshairs: {
        //   show: query.style === "simple" ? false : true
        // },
        // axisTicks: {
        //   show: query.style === "simple" ? false : true
        // },
        // axisBorder: {
        //   show: query.style === "simple" ? false : true
        // },
      },
      grid: {
        yaxis: {
          lines: {
            show: query.style === "simple" ? false : true
          }
        }
      },
    });


    if (labels) {
      options.labels = labels;
    }

    if (query.type == 'pie') {
      var series = [];
      var labels = [];
      _.forEach(_series, function (s) {
        if (!s.data.length || s.data[0].y == null && !query.keepNull) {
          return;
        }
        if (query.keepNull && s.data[0].y == null) {
          s.data[0].y = 0;
        }

        series.push(s.data[0].y);
        labels.push(s.name);
      });
      options.series = series;
      options.labels = labels;
      options.dataLabels.enabled = true;

      if (series.length == 0) {
        $(chartElement).html('No data...');
        if (query.hideIfEmpty) {
          $(chartElement).closest('[debt-graph]').hide();
        }
        return;
      }
    }

    console.info('chart options', options);

    if (options.chart.type == 'radialBar') {
      options.plotOptions = {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          dataLabels: {
            name: {
              offsetY: -5,
              show: true,
              fontSize: '13px'
            },
            value: {
              offsetY: -40,
              fontSize: '22px'
            }
          }
        }

      }
    }

    if (options.chart.type == 'stackedBar') {
      options.chart.type = 'bar';
      options.chart.stacked = true;
    }

    if (options.chart.type == 'stackedArea') {
      options.chart.type = 'area';
      options.chart.stacked = true;
    }

    if (options.chart.type == 'radialBar') {
      options.labels = labels;
    }


    if (query.stroke) {
      options.stroke = query.stroke;
      options.yaxis.min = 0;
    }

    if (options.chart.type == 'line') {
      options.stroke = options.stroke || {};
      options.stroke.width = 2;
    }


    if (chartElement.apexChart) {
      chartElement.apexChart.destroy();
    }

    console.log('options', options);

    try {
      chartElement.apexChart = new ApexCharts(chartElement, options);

      chartElement.apexChart.render();
    } catch (err) {
      console.error(err);
    }


  });
}

function renderTable(tableDiv, query) {
  var queue = [];

  //keyValues.forEach(function (seriesKey) {
  //  countryValues.forEach(function (countryKey) {
  //  });
  //});

  fetch('/_debt-frontend.php/data-v2/series?json=' + encodeURIComponent(JSON.stringify(query))).then(function (resp) {
    return resp.json();
  }).then(function (_series) {
    console.log('series', _series);
    // Render table

    if (!(query && _series && _series.length && _series[0].data)) {
      return;
    }


    var _table = $('<table class="table table-bordered"></table>');
    var _tbody = $('<tbody></tbody>');

    _table.append(_tbody);

    console.log('table series', _series);

    var _headerRow = $('<tr>');
    _headerRow.append('<th>');
    _.forEach(_series, function (series) {
      var _cell = $('<th>');
      _cell.html(series.name);
      _headerRow.append(_cell);
    });
    _tbody.append(_headerRow);

    _.forEach(_series[0].data, function (point, yearIdx) {
      var _row = $('<tr>');
      var _cell = $('<td>');
      _cell.html(point.x);

      _row.append(_cell);

      _.forEach(_series, function (series) {
        var _cell = $('<td>');
        _cell.css('textAlign', 'right');
        _cell.html(
          (series.data[yearIdx].y ? series.data[yearIdx].y.toLocaleString() : '')
        );
        _row.append(_cell);
      });

      _tbody.append(_row);

    });

    $(tableDiv).html('');
    $(tableDiv).append(_table);

    var exportBtn = $('<a></a>');
    exportBtn.attr('target', '_blank');
    exportBtn.attr('class', 'btn btn-default btn-sm pull-right');
    exportBtn.attr('href', '/_debt-frontend.php/data-v2/export?json=' + JSON.stringify(query));
    exportBtn.html('<i class="fa fa-file-excel-o"></i> ' + window.translate['Export']);

    $(tableDiv).append(exportBtn);
  });
}

//renderGraph(document.querySelector("#chart"), {type: 'bar', xAxisBy: 'year', xYears: ['2015/16', '2016/17', '2017/18'], ySeries: ['domestic-currency-foreign-currency-USD'], yCountries: ['SOUTH AFRICA']});
function renderGraphs(currencyOverride, compareCountry) {
  //var currencyValue = currencyOverride || 'usd';

  $('[debt-graph]').each(function () {
    var config = $(this).data('config');

    config = _.cloneDeep(config);

    console.log('compareCountry', compareCountry);
    if (compareCountry) {
      config.yCountries.push(compareCountry);
    }
    if (currencyOverride) {
      config.currency = currencyOverride;
    }


    config.currency = config.currency || 'usd';

    var graphHolder = $(this).find('[graph]');

    $(this).find('[graph-link]').remove();

    if (graphHolder.length == 0) {
      graphHolder = $('<div></div>');
      $(this).append(graphHolder);
    }
    graphHolder.html('');
    var graphDiv = $('<div></div>');
    graphHolder.append(graphDiv);

    var link = $('<a graph-link href="/' + window.lang + '/our-work/adm/countries/' + window.countrySlug + '/data-explorer/#?query=' + encodeURIComponent(JSON.stringify(config)) + '" class="graph-link">' + window.translate["Explore data"] + ' <i class="fa fa-search-plus"></i></a>');
    $(this).append(link);


    renderGraph(graphDiv[0], config);

    //outerdiv.css('height', '200px');
    //outerdiv.css('overflow', 'hidden');
  });

  $('[debt-table]').each(function () {
    var config = $(this).data('config');
    config = _.cloneDeep(config);
    $(this).html('');
    var outerdiv = $('<div></div>');
    var tableDiv = $('<div></div>');
    outerdiv.append(tableDiv);
    $(this).append(outerdiv);

    if (currencyOverride) {
      config.currency = currencyOverride;
    }

    config.currency = config.currency || 'usd';

    if (compareCountry) {
      config.yCountries.push(compareCountry);
    }

    renderTable(tableDiv[0], config);

    //outerdiv.css('height', '200px');
    //outerdiv.css('overflow', 'hidden');
  });
}

$(function () {


  renderGraphs();

  $(window).on('shown.bs.collapse', function () {
    setTimeout(function () {
      renderGraphs();
    }, 250);
  });

});

angular.module('debtMonitorApp').component('factoid', {
  template: '<dl ng-if="!($ctrl.hideEmpty && !$ctrl.data.value)" class="fact-simple">' +
    '<dt class="">' +
    '<span class="factoid-data-title" ng-bind="$ctrl.data.data_title"></span>' +
    '<button title="{{ $ctrl.data.label }}" class="btn btn-default btn-compare" ng-click="$parent.$parent.showQuestion($ctrl.data)"><i class="fa fa-search"></i> Compare</button>' +
    '</dt>' +
    '<dd>' +
    '<i ng-if="$ctrl.icon && $ctrl.data.value != \'N/A\'" class="fa {{ $ctrl.icon }}"></i> ' +
    '<span class="fact-value" ng-bind-html="$ctrl.data.value_formatted ? $ctrl.data.value_formatted : $ctrl.data.value"></span>' +
    '</dd>' +
    '</dl>',
  controller: ['$scope', '$element', '$compile', '$rootScope', function ($scope, $element, $compile, $rootScope) {
    // console.log('factoid', this);
    this.$onChanges = function () {
      var self = this;
      console.log('this.hideEmpty', this.hideEmpty);

      if (this.data) {
        if (this.data.value == 'Yes') {
          this.data.value_formatted = '<span class="fact-yes"><i class="fa fa-check"></i> Yes</span>';
        }
        if (this.data.value == 'No') {
          this.data.value_formatted = '<span class="fact-no"><i class="fa fa-times"></i> No</span>';
        }
        if (this.data.value == 'Sim') {
          this.data.value_formatted = '<span class="fact-yes"><i class="fa fa-check"></i> Sim</span>';
        }
        if (this.data.value == 'Não') {
          this.data.value_formatted = '<span class="fact-no"><i class="fa fa-times"></i> Não</span>';
        }
        if (this.data.value == 'Oui') {
          this.data.value_formatted = '<span class="fact-yes"><i class="fa fa-check"></i> Oui</span>';
        }
        if (this.data.value == 'Non') {
          this.data.value_formatted = '<span class="fact-no"><i class="fa fa-times"></i> Non</span>';
        }
        if (this.data.value == 'N/A') {
          this.data.value_formatted = '<span class="fact-na">N/A</span>';
        }

        $rootScope.$watch('glossary', function (newValue) {
          if (newValue) {
            console.log('factoid glossary', $rootScope.glossary, self.data.data_title, self.data.value);

            $rootScope.glossary.sort(function (a, b) {
              return a.term.length > b.term.length ? -1 : 1;
            });

            if (self.data.value && !self.data.value_formatted) {
              var valueStr = self.data.data_title;

              angular.forEach($rootScope.glossary, function (item) {
                valueStr = valueStr.replace(new RegExp('(' + item.term + ')', 'ig'), '<debt-term>$1</debt-term>');
              });

              var el = $compile('<span>' + self.data.value + '</span>')($scope);
              $element.find('.fact-value').html(el);

            }

            if (self.data.data_title) {
              var titleStr = self.data.data_title;

              angular.forEach($rootScope.glossary, function (item) {
                titleStr = titleStr.replace(new RegExp('(' + item.term + ')', 'ig'), '<debt-term>$1</debt-term>');
              });

              var el = $compile('<span>' + titleStr + '</span>')($scope);
              $element.find('.factoid-data-title').html(el);

            }
          }
        });
      }

    };


    var chart = angular.element(document.createElement('chart'));
    var el = $compile(chart)($scope);

    //where do you want to place the new element?
    angular.element(document.body).append(chart);

    $scope.insertHere = el;

  }],
  bindings: {
    data: '<',
    icon: '<',
    hideEmpty: '<',
  }
});

angular.module('debtMonitorApp').component('factoidList', {
  template: '<dl class="fact-simple">' +
    '<dt class="">' +
    '<span ng-bind="$ctrl.data.data_title"></span>' +
    '<button title="{{ $ctrl.data.label }}" class="btn btn-default btn-compare" ng-click="$parent.showQuestion($ctrl.data)"><i class="fa fa-search"></i> Compare</button>' +
    '</dt>' +
    '<dd class=""><ul><li ng-repeat="item in $ctrl.data.list">{{ item }}</li></ul></dd>' +
    '</dl>',
  controller: function () {
  },
  bindings: {
    data: '='
  }
});

angular.module('debtMonitorApp').component('factoidFrequency', {
  template: '<div class="fact-simple">' +
    '<h4 class="fact-heading" ng-bind="$ctrl.data.data_title">' +
    '<span ng-bind="$ctrl.data.data_title"></span>' +
    '<button title="{{ $ctrl.data.label }}" class="btn btn-default btn-compare" ng-click="$parent.showQuestion($ctrl.data)"><i class="fa fa-search"></i> Compare</button>' +
    '</h4>' +
    '<div class="frequency-stat" ng-bind="$ctrl.data.value"></div>' +
    '</div>',
  controller: function () {
  },
  bindings: {
    data: '='
  }
});
angular.module('debtMonitorApp').component('factoidDescription', {
  template: '<div class="fact-simple">' +
    '<h4 class="fact-heading" ng-bind="$ctrl.data.data_title">' +
    '<span ng-bind="$ctrl.data.data_title"></span>' +
    '<button title="{{ $ctrl.data.label }}" class="btn btn-default btn-compare" ng-click="$parent.showQuestion($ctrl.data)"><i class="fa fa-search"></i> Compare</button>' +
    '</h4>' +
    '<div class="description" ng-bind="$ctrl.data.value"></div>' +
    '</div>',
  controller: function () {
  },
  bindings: {
    data: '='
  }
});
angular.module('debtMonitorApp').component('factoidPanel', {
  template: '' +
    '<div class="panel panel-default">' +
    '<div class="panel-heading">' +
    '<button title="{{ $ctrl.data.label }}" class="btn btn-default btn-compare" ng-click="$parent.showQuestion($ctrl.data)"><i class="fa fa-search"></i> Compare</button>' +
    '<h3 class="panel-title">' +
    '{{ $ctrl.data.data_title }}' +
    '</h3>' +
    '</div>' +
    '<div class="panel-body">' +
    '<div ng-bind-html="$ctrl.data.value"></div>' +
    '</div>' +
    '</div>'
  ,
  controller: function () {
  },
  bindings: {
    data: '='
  }
});


angular.module('debtMonitorApp').component('factoidStatement', {
  template: '<div>' +
    '<div ng-bind="$ctrl.data.statement" class="statement" ng-class="{\'statement-good\': $ctrl.data.value_bool, \'statement-bad\': !$ctrl.data.value_bool}"></div>' +
    '</div>',
  controller: function () {

  },
  bindings: {
    data: '='
  }
});


angular.module('debtMonitorApp').component('factoidYesno', {
  template: '<span ng-bind-html="$ctrl.data.value_formatted || $ctrl.data.value"></span>',
  controller: function () {
    this.$onChanges = function () {
      if (this.data) {
        if (this.data.value == 'Yes') {
          this.data.value_formatted = '<span class="fact-yes"><i class="fa fa-check"></i> Yes</span>';
        }
        if (this.data.value == 'No') {
          this.data.value_formatted = '<span class="fact-no"><i class="fa fa-times"></i> No</span>';
        }
        if (this.data.value == 'N/A') {
          this.data.value_formatted = '<span class="fact-na">N/A</span>';
        }
      }
    };

  },
  bindings: {
    data: '<'
  }
});

angular.module('debtMonitorApp').component('factoidScale', {
  template: '<dl class="fact-simple">' +
    '<dt class="">' +
    '<span ng-bind="$ctrl.data.data_title"></span>' +
    '<button title="{{ $ctrl.data.label }}" class="btn btn-default btn-compare" ng-click="$parent.showQuestion($ctrl.data)"><i class="fa fa-search"></i> Compare</button>' +
    '</dt>' +
    '<dd class="">' +
    '<div>{{ $ctrl.data.value }}</div>' +
    '<div class="progress">' +
    '<div class="progress-bar progress-bar-striped"  style="width: {{ $ctrl.scaleValue }}%">' +
    '</div>' +
    '</div>' +
    '' +
    '' +
    '</dd>' +
    '</dl>',
  //
  // '<div>' +
  // '<div class="progress">' +
  // '<div class="progress-bar progress-bar-striped"  style="width: {{ $ctrl.scaleValue }}%">' +
  // '</div>' +
  // '</div>' +
  // '<div>{{ $ctrl.data.value }}</div>' +
  // '</div>',
  controller: function () {
    this.$onChanges = function () {
      if (this.data) {
        if (this.data.value === 'Very inaccurate') {
          this.scaleValue = 5;
        }
        if (this.data.value === 'Somewhat inaccurate') {
          this.scaleValue = 33;
        }
        if (this.data.value === 'Somewhat accurate') {
          this.scaleValue = 66;
        }
        if (this.data.value === 'Very accurate') {
          this.scaleValue = 100;
        }

      }
    };

  },
  bindings: {
    data: '<'
  }
});

angular.module('debtMonitorApp').directive('uiCheckbox', function ($compile) {
  return {
    restrict: 'E', //attribute or element
    require: 'ngModel',
    scope: {
      ngModels: '=',
      ngModel: '=',
      uiCheckedAtTop: '=',
      uiLoading: '=',
      uiDisableChoice: '=',
      uiTopLabel: '=',
      uiShowFilter: '=',
      uiName: '=',
      //bindAttr: '='
    },
    template: `<div class="ui-checkbox">
            <div class="ui-checkbox-top" ng-if="uiCheckedAtTop">
                <button ng-if="selectedModels.length" ng-repeat="item in selectedModels" class="ui-checkbox-button" ng-class="{'ui-checkbox-checked': item.$selected}" ng-click="change(item)"><!-- ng-model="item.$selected"  -->
                  <i class="fa fa-square-o" ng-class="{'fa-check-square': item.$selected}"></i> <span ng-bind="item[uiTopLabel || 'label']"></span>
                </button>
            </div>
            <div ng-class="{'ui-checkbox-scrollable': uiCheckedAtTop}">
            <div class="ui-checkbox-depth-{{ item.depth }}" ng-repeat="item in ngModels">
            <button ng-if="!uiLoading" ng-disabled="item[uiDisableChoice]" class="ui-checkbox-button" ng-class="{'ui-checkbox-checked': item.$selected}" ng-click="change(item)"><!-- ng-model="item.$selected"  -->
                  <i class="fa fa-square-o" ng-class="{'fa-check-square': item.$selected}"></i>
                  <span ng-bind="item.label"></span>
<!--                  <span class="label label-default" ng-if="item.show_usd">USD</span>-->
<!--                  <span class="label label-default" ng-if="item.show_local">{{ item.local }}</span>-->
<!--                  <span class="label label-default" ng-if="item.type == '%'">%</span>-->
              </button><!-- ng-hide="uiCheckedAtTop && item.$selected"  -->
            </div>
              
              <div ng-if="uiLoading"><img src="/images/oval.svg"></div>
            </div>
        </div>`,
    link: function ($scope, elem, attr, ngModelCtrl) {
      console.log($scope.uiName, '$scope.uiCheckedAtTop', $scope.uiCheckedAtTop);

      $scope.change = function (item) {
        console.log($scope.uiName, 'uiCheckbox:change...');

        item.$selected = !item.$selected;

        if ($scope.uiDisableChoice) {
          if (item[$scope.uiDisableChoice]) {
            item.$selected = false;
          }
        }

        updateModel();
      };

      // $scope.ngModel = $scope.ngModel || [];
      $scope.$watch('ngModel', function () {
        console.log($scope.uiName, 'uiCheckbox:watch:ngModel...');
        $scope.selectedModels = _.filter($scope.ngModels, '$selected');

        _.forEach(_.intersectionBy($scope.ngModel, $scope.ngModels, 'value'), function (item) {
          item.$selected = true;
        });


      }, true);
      $scope.$watch('ngModels.length', function () {
        console.log($scope.uiName, 'uiCheckbox:watch:ngModels.length...');
      });
      $scope.$watch('ngModels', function () {
        console.log($scope.uiName, 'uiCheckbox:watch:ngModels...');

        _.forEach(_.intersectionBy($scope.ngModel, $scope.ngModels, 'value'), function (item) {
          item.$selected = true;
        });

        if ($scope.uiDisableChoice) {
          $scope.ngModels.map(function (item) {
            if (item[$scope.uiDisableChoice]) {
              item.$selected = false;
            }
          });
        }

      }, true);


      function updateModel() {
        console.log($scope.uiName, 'uiCheckbox:updateModel...');

        if ($scope.uiDisableChoice) {
          $scope.ngModels.map(function (item) {
            if (item[$scope.uiDisableChoice]) {
              item.$selected = false;
            }
          });
        }

        $scope.selectedModels = _.filter($scope.ngModels, '$selected');

        if ($scope.ngModel) {

          ngModelCtrl.$setViewValue(_.filter($scope.ngModels, '$selected'));

        }
      }

    }
  };
});


angular.module('debtMonitorApp').directive('uiCheckboxTree', function ($compile) {
  return {
    restrict: 'E', //attribute or element
    require: 'ngModel',
    scope: {
      items: '=',
      ngModel: '=',
      uiCheckedAtTop: '=',
      uiLoading: '=',
      uiDisableChoice: '=',
      uiTopLabel: '=',
      uiShowFilter: '=',
      loadKeysIndex: '=',
      //bindAttr: '='
    },
    template: `
        <script id="debt-checkboxes-tree" type="text/ng-template">
                
                <div ng-repeat="item in items" class="ui-checkbox-tree">
                    <span ng-if="item.children.length" class="ui-checkbox-expand">
                        <i class="fa fa-chevron-right" ng-if="!item.$expanded" ng-click="item.$expanded = true"></i>
                        <i class="fa fa-chevron-down" ng-if="item.$expanded" ng-click="item.$expanded = false"></i>
                    </span>
                    
                    <span class="ui-checkbox-button" ng-if="item.is_disabled">
                          <span ng-bind="item.label"></span>
                    </span>
                      
                    <button  ng-if="!item.is_disabled" class="ui-checkbox-button" ng-class="{'ui-checkbox-checked': item.$selected}" ng-click="change(item)">
                          <i class="fa fa-square-o" ng-if="!item.is_disabled" ng-class="{'fa-check-square': item.$selected}"></i>
                          <span ng-bind="item.label"></span>
                          <!--<span class="label label-default" ng-if="item.show_usd">USD</span>
                          <span class="label label-default" ng-if="item.show_local">{{ item.local }}</span>-->
                          <span class="label label-default" ng-if="item.type == '%'">%</span>
                    </button>
                    
                    <div ng-if="item.children.length" ng-show="item.$expanded">
                        <div ng-include="'debt-checkboxes-tree'" ng-init="items = item.children"></div>
                    </div>
                </div>
                      
        </script>

        <div class="ui-checkbox-scrollable">
          <div ng-include="'debt-checkboxes-tree'" ng-if="!uiLoading"></div>
          <div ng-if="uiLoading"><img src="/images/oval.svg"></div>
        </div>
    `,
    link: function ($scope, elem, attr, ngModelCtrl) {
      console.log('$scope.uiCheckedAtTop', $scope.uiCheckedAtTop);

      $scope.change = function (item) {
        item.$selected = !item.$selected;

        if (item.$selected && item.children && item.children.length) {
          item.$expanded = true;
        }

        updateModel();
      };

      // $scope.ngModel = $scope.ngModel || [];
      $scope.$watch('ngModel', function () {
        // $scope.selectedModels = findSelected($scope.items);

        console.log("uiCheckboxTree:watch:ngModel");


        _.forEach(_.intersectionBy($scope.ngModel, $scope.dataKeysIndex, 'value'), function (item) {
          if (!item.is_disabled) {
            item.$selected = true;
          }
        });

      }, true);

      $scope.$watch('loadKeysIndex', function () {

        console.log("uiCheckboxTree:watch:items");

        $scope.dataKeysIndex = [];

        function recurse(item, parent) {
          $scope.dataKeysIndex.push(item);

          item.$_parent = parent;

          _.forEach(item.children, function (child) {
            recurse(child, item);
          });
        }

        _.forEach($scope.items, function (child) {
          recurse(child, null);
        });

        if ($scope.items) {
          expandToDepth($scope.items, 2);
        }

        // _.forEach(_.intersectionBy($scope.ngModel, $scope.dataKeysIndex, 'value'), function (item) {
        //   item.$selected = true;
        // });

        // _.forEach(_.intersectionBy($scope.ngModel, $scope.ngModels, 'value'), function (item) {
        //   item.$selected = true;
        // });

      }, true);


      function expandToDepth(items, depth) {

        _.forEach(items, function (item) {

          if (item.depth <= depth) {
            item.$expanded = true;
          }


          var _item = item;

          if (item.$selected) {
            while (_item.$_parent) {
              _item.$_parent.$expanded = true;
              _item = _item.$_parent;
            }
          }

          if (item.children) {
            expandToDepth(item.children, depth);
          }


        });

      }


      function findSelected(items, arr) {
        arr = arr || [];

        _.forEach(items, function (item) {
          if (item.$selected) {
            arr.push(item);
          }
          if (item.children) {
            findSelected(item.children, arr);
          }
        });

        return arr;
      }

      function updateModel() {
        console.log('updateModel...');

        $scope.selectedModels = findSelected($scope.items); // _.filter($scope.ngModels, '$selected');

        console.log('$scope.selectedModels', $scope.selectedModels);

        if ($scope.ngModel) {

          ngModelCtrl.$setViewValue($scope.selectedModels);

        }
      }

    }
  };
});


angular.module('debtMonitorApp').directive('debtTerm', function ($http, $rootScope) {
  return {
    template: '<div popover-trigger="\'outsideClick\'" uib-popover="{{ description }}" popover-title="{{ term }}"><div ng-transclude></div></div>',
    restrict: 'E',
    scope: {
      search: '=search'
    },
    transclude: true,
    link: function ($scope, element, attrs, ctrl, transclude) {


      $rootScope.$watch('glossary', function (newValue) {
        if (newValue) {
          $scope.glossary = $rootScope.glossary;
          var lcsearch;

          if ($scope.search) {
            lcsearch = $scope.search.toLowerCase();
          } else {
            transclude($scope, function (clone) {
              lcsearch = clone[0].nodeValue.toLowerCase();
            });
          }

          if ($rootScope.glossaryByTerm[lcsearch] || $rootScope.glossaryByTermAlt[lcsearch]) {
            var item = $rootScope.glossaryByTerm[lcsearch] || $rootScope.glossaryByTermAlt[lcsearch];
            $scope.term = item.term;
            $scope.description = item.description;
          }


        }
      });

    }
  }
});
