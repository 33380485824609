var cabriColors = [
  "#106986",
  "#59aec1",
  "#f38539",
  "#2c3838",
  "#7f8948",
  "#5bb750",
  "#5c6164",
  "#85a2b4",
  "#a0a474",
  "#f69856",
  "#7fc36e",
  "#c4ced5",
  "#d4d4be",
  "#acd1dc",
  "#afafb1",
  "#fccaa3",
  "#92b1c9",
  "#cbd4dd",
  "#c0deb2",
];

function customTooltipFn(tooltipModel) {
  // Tooltip Element
  var tooltipEl = document.getElementById('chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<table></table>';
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
    return bodyItem.lines;
  }

  // Set Text
  if (tooltipModel.body) {
    var titleLines = tooltipModel.title || [];
    var bodyLines = tooltipModel.body.map(getBody);

    var innerHtml = '<thead>';

    titleLines.forEach(function (title) {
      innerHtml += '<tr><th>' + title + '</th></tr>';
    });
    innerHtml += '</thead><tbody>';

    bodyLines.forEach(function (body, i) {
      var colors = tooltipModel.labelColors[i];
      var style = 'background:' + colors.backgroundColor;
      style += '; border-color:' + colors.borderColor;
      style += '; border-width: 2px';
      var span = '<span style="' + style + '"></span>';

      body = body.join('<br>');

      innerHtml += '<tr><td>' + span + body + '</td></tr>';
    });
    innerHtml += '</tbody>';

    var tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  // `this` will be the overall tooltip
  var position = this._chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + -15 + 'px';
  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 15 + 'px';
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
  tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.borderRadius = '5px';

}

if (typeof Chart !== "undefined") angular.module('healthSurveyApp', ['ui.select', 'ngSanitize', 'ui.bootstrap', 'chart.js'])
  .config(['$locationProvider', 'ChartJsProvider', function ($locationProvider, ChartJsProvider) {
    $locationProvider.hashPrefix('');

    // ChartJsProvider.setOptions({ colors : [ '#803690', '#00ADF9', '#DCDCDC', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'] });
    Chart.plugins.unregister(ChartDataLabels);

    Chart.defaults.global.colors = cabriColors;

    Chart.defaults.scale.ticks.maxRotation = 90;

    Chart.defaults.global.defaultFontFamily = "Calibri, sans-serif";

    // ChartJsProvider.setOptions({
    //   colors : [
    //     "rgb(159,204,0)","rgb(250,109,33)","rgb(154,154,154)"
    //   ]
    // });

  }])
  .run(['$rootScope', '$http', function ($rootScope, $http) {
    $rootScope._ = _;
  }])
  .controller('HealthSurveyController', ["$scope", "$http", "$sce", "$location", "$q", function ($scope, $http, $sce, $location, $q) {

  }])
  .controller('HealthSurveyEntryController', ["$scope", "$http", "$sce", "$location", "$q", function ($scope, $http, $sce, $location, $q) {

    $http.get('/_debt-frontend.php/health/country-answers/' + window.countryCode + '?').then((resp) => {
      $scope.data = resp.data;
      $scope.questionsByCode = _.keyBy($scope.data.questions, 'code');
    });

    $scope.questionFromCode = (code) => {
      return $scope.questionsByCode ? $scope.questionsByCode[code] : null;
    };

    $scope.allQuestionsHaveOptions = (codes) => {
      var flag = true;

      angular.forEach(codes, function (code) {
        if (!$scope.questionFromCode(code).answers[0].answer_options.length) {
          flag = false;
        }
      });


      return flag;
    };
  }])
  .directive('healthQuestionFormattedAnswer', function ($http, $rootScope) {
    return {
      template: `
      <div ng-if="question.answers[0].answer" style="white-space: pre-line;" ng-bind-html="formatAnswer(question.answers[0].answer)"></div>
    <div ng-if="question.answers[0].answer_options.length">
    <div ng-repeat="answerOption in question.answers[0].answer_options">
    <div class="label label-info" style="white-space: normal; word-break: normal">{{ answerOption.option.label_formatted }}</div></div>
</div>`,
      restrict: 'E',
      scope: {
        'question': '=',
      },
      link: function ($scope, element, attrs, ctrl, transclude) {

        $scope.formatAnswer = function (answer) {
          if (answer === 'Yes') {
            return '<span class="fact-yes"><i class="fa fa-check"></i> Yes</span>';
          }
          if (answer === 'No') {
            return '<span class="fact-no"><i class="fa fa-times"></i> No</span>';
          }
          if (!answer) {
            return '&mdash;';
          }

          return answer;
        }

      }
    }
  })

  .directive('healthRow', function ($http, $rootScope) {
    return {
      template: `
<th>
  {{ question.question_sub || question.question }}
</th>
<td>
  <health-question-formatted-answer question="question"></health-question-formatted-answer>
</td>
`,
      restrict: 'A',
      scope: {
        question: '=',
        hideEmpty: '=',
      },
      // transclude: true,
      link: function ($scope, element, attrs, ctrl, transclude) {
        if ($scope.question) {
          $scope.hide = $scope.hideEmpty ? !($scope.question.answers[0].answer || $scope.question.answers[0].answer_options.length) : false;
        }
      }
    }
  })

  .directive('healthFactoid', function ($http, $rootScope) {
    return {
      template: `
<dl class="fact-simple" ng-if="!hide">
  <dt class="">
    <span class="factoid-data-title">
        {{ question.question }}
        <small ng-if="question.question_sub">{{ question.question_sub }}</small>
    </span>
  </dt>
  <dd>
    <i ng-if="icon" class="fa {{ icon }}"></i> 
    <span class="fact-value">
        <health-question-formatted-answer question="question"></health-question-formatted-answer>
    </span>
  </dd>
</dl>
`,
      restrict: 'E',
      scope: {
        question: '=',
        hideEmpty: '=',
      },
      // transclude: true,
      link: function ($scope, element, attrs, ctrl, transclude) {
        $scope.hide = false;

        $scope.$watch('question', function () {
          if ($scope.question) {
            if ($scope.hideEmpty) {
              $scope.hide = !($scope.question.answers[0].answer.length || $scope.question.answers[0].answer_options.length);
            }
          }
        });

      }
    }
  })
  .directive('healthTable', function ($http, $rootScope) {
    return {
      template: `
<table class="health-table">
    <tr>
        <th ng-repeat="question_option in data.question_options" style="width: {{ 100/data.question_options.length }}%">{{ question_option.label_formatted || question_option.label }}</th>
    </tr>
    <tr>
    <td ng-repeat="question_option in data.question_options">
        <div ng-repeat="answer in question_option.answers">{{ answer.country_formatted }}</div>
    </td>
    </tr>    
</table>`,
      restrict: 'E',
      scope: {
        'query': '=',
      },
      // transclude: true,
      link: function ($scope, element, attrs, ctrl, transclude) {
        $http.get('/_debt-frontend.php/health/table?query=' + JSON.stringify($scope.query)).then((resp) => {
          $scope.data = resp.data;
        });
      }
    }
  })
  .directive('healthTable2', function ($http, $rootScope) {
    return {
      template: `
<div class="table-responsive">
<table class="health-table">
<tr class="group-heading-row group-heading-row-first" ng-if="headingGroups">
<th></th>
<th ng-repeat="headingGroup in headingGroups" colspan="{{ headingGroup.cols }}">{{ headingGroup.label }}</th>
</tr>
    <tr class="group-heading-row group-heading-row-second">
    <th>Country</th>
    <th ng-repeat="question in data.questions" style="width: {{ 100/(data.questions.length+1) }}%">{{ question.question_sub || question.question }}</th>
    </tr>
    <tr ng-repeat="row in data.countries" class="country-row">
    <th class="country-name">{{ row.name }}</th>
    <td ng-repeat="(answerId, options) in row.answers" data-answer="{{ answerId }}">
    <div ng-repeat="option in options" ng-if="!option.is_other" class="label label-info">
        {{ option.label_formatted || option.label }}
    </div>
    <div ng-repeat="option in options" ng-if="option.is_other" class="label label-default">
            {{ option.label_formatted || option.label }}
</div>
    
</td>
    </tr>
</table></div>`,
      restrict: 'E',
      scope: {
        query: '=',
        headingGroups: '='
      },
      // transclude: true,
      link: function ($scope, element, attrs, ctrl, transclude) {

        $http.get('/_debt-frontend.php/health/table2?query=' + JSON.stringify($scope.query)).then((resp) => {
          $scope.data = resp.data;
        });
      }
    }
  })
  .directive('healthCheckmarkTable', function ($http, $rootScope) {
    return {
      template: `
<div class="table-responsive">
<table class="health-table">

    <tr class="group-heading-row group-heading-row-second">
    <th>Country</th>
    <th ng-repeat="option in data.options" class="text-center" style="width: {{ 100/(data.options.length+1) }}%">{{ option.label_formatted }}</th>
    </tr>
    <tr ng-repeat="row in data.countries" class="country-row">
    <th class="country-name">{{ row.name }}</th>
    <td ng-repeat="option in data.options" class="text-center">
        <div ng-if="_.filter(row.answers, {uid: option.uid}).length"><i class="fa fa-check"></i></div>
    </td>
    </tr>
</table></div>`,
      restrict: 'E',
      scope: {
        query: '='
      },
      // transclude: true,
      link: function ($scope, element, attrs, ctrl, transclude) {
        $http.get('/_debt-frontend.php/health/table3?query=' + JSON.stringify($scope.query)).then((resp) => {
          $scope.data = resp.data;
          $scope._ = _;
        });
      }
    }
  })
  .directive('healthGraphCountries', function ($http, $rootScope) {
    return {
      template: `<canvas-container></canvas-container>`,
      restrict: 'E',
      scope: {
        query: '='
      },
      // transclude: true,
      link: function ($scope, element, attrs, ctrl, transclude) {
        $scope._ = _;

        var canvasContainer = element[0].querySelectorAll('canvas-container')[0];

        var colors = cabriColors;

        // console.log('element', canvas);

        $http.get('/_debt-frontend.php/health/answers?query=' + encodeURIComponent(JSON.stringify($scope.query))).then((resp) => {
          var labels = [];
          var data = [];

          _.forEach(resp.data.answers, (answer) => {
            labels.push(answer.country.name);
            data.push(answer.answer);
          });

          var chartData = {
            labels: labels,
            datasets: [
              {
                label: resp.data.question.question,
                backgroundColor: $scope.query.color ? colors[$scope.query.color] : '#1B647E',
                // borderColor: [],
                borderWidth: 0,
                data: data,
              }
            ]
          };

          var chart;

          function drawChart() {
            $(canvasContainer).html('<canvas style="width: 100%; height: 300px;"></canvas>');

            chart = new Chart($(canvasContainer).find('canvas')[0].getContext("2d"), {
              type: 'bar',
              data: chartData,
              options: {
                responsive: false,
                scales: {
                  xAxes: [{
                    ticks: {
                      minRotation: 90
                    }
                  }]
                }
              }
            });
          }

          drawChart();

          $(window).on('shown.bs.collapse', function () {
            setTimeout(function () {
              chart.destroy();
              // canvas.removeAttribute('height');
              // canvas.removeAttribute('width');
              drawChart();
            }, 250);
          });

        });

      }
    }
  })

  .directive('healthGraphTotals', function ($http, $rootScope) {
    return {
      template: `
<div>
    <canvas-container></canvas-container>
  <div class="d-flex" style="padding-left: 30px;" ng-if="!query.hide_graph_title">
      <div ng-repeat="question in data.questions" class="text-center health-graph-footer-item" style="width: {{ 100/data.questions.length }}%;">
         <span ng-if="!hideTitle">{{ question.question }}</span>
         <div ng-if="question.question_sub">{{ question.question_sub }}</div>
      </div>
  </div>
</div>
`,
      restrict: 'E',
      scope: {
        query: '=',
        hideTitle: '=',
      },
      // transclude: true,
      link: function ($scope, element, attrs, ctrl, transclude) {
        $scope._ = _;

        var canvasContainer = element[0].querySelectorAll('canvas-container')[0];
        // var canvas = element[0].querySelectorAll('canvas')[0];

        var colorByValue = {
          "Yes": '#1B647E',
          "No": '#EF843B',
          "No response": 'rgb(154,154,154)',
        }

        var baseColors = cabriColors;

        var colors = [];

        $scope.query.height = $scope.query.height || 300;

        // console.log('element', canvas);

        $http.get('/_debt-frontend.php/health/answer-totals?query=' + encodeURIComponent(JSON.stringify($scope.query))).then((resp) => {
          $scope.data = resp.data;

          var labels = [];
          var data = [];
          var answersArr = [];


          _.forEach(resp.data.questions, (question) => {
            _.forEach(question.answerByValue, (answers, value) => {
              value = value.split("\n");
              labels.push(value);
              data.push(answers.length);
              answersArr.push(answers);

              if (colorByValue[value]) {
                colors.push(colorByValue[value]);
              }
            });
          });

          console.log('chart', data, labels);

          colors = colors.concat(baseColors);

          var chartData = {
            labels: labels,
            datasets: [
              {
                label: ["some label"],
                backgroundColor: colors,
                // borderColor: [],
                borderWidth: 0,
                data: data,
              }
            ]
          };

          // var ctx = canvas;
          var type = $scope.query.type || 'bar';
          var plugins = [];

          var options = {
            responsive: false,
          };

          options.tooltips = {

            enabled: false,

            custom: customTooltipFn,

            callbacks: {
              label: function (tooltipItem, data) {

                console.log('label', tooltipItem, data);

                var countries = answersArr[tooltipItem.index];

                var labels = _.map(countries, (item) => item.country.name);

                // labels.unshift(data.labels[tooltipItem.index] + ": " + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);

                return labels;
              }
            }
          };

          if (type === 'bar' || type === 'horizontalBar') {

            options.scales = {
              yAxes: [{
                ticks: {beginAtZero: true}
              }],
              xAxes: [{
                gridLines: {
                  display: false
                },
                ticks: {
                  beginAtZero: true
                }
              }]
            };

          }

          if ($scope.query.labelRotation) {
            options.scales.xAxes[0].ticks.maxRotation = $scope.query.labelRotation;
            options.scales.xAxes[0].ticks.minRotation = $scope.query.labelRotation;
          }

          if (type === 'pie') {
            options.legend = {
              display: true,
            };
            plugins.push(ChartDataLabels);
            options.plugins = {
              // Change options for ALL labels of THIS CHART
              datalabels: {
                color: '#fff'
              }
            };

            chartData.datasets[0].borderColor = '#FFFFFF';
            chartData.datasets[0].borderWidth = 2;

            // console.log('$scope.query.hide_graph_title', $scope.query.hide_graph_title, options);

          }

          var chart;

          function drawChart() {
            $(canvasContainer).html('<canvas style="width: 100%; height: ' + $scope.query.height + 'px;"></canvas>');

            chart = new Chart($(canvasContainer).find('canvas')[0].getContext("2d"), {
              plugins: plugins,
              type: type,
              data: chartData,
              options: options,
            });
          }

          drawChart();

          $(window).on('shown.bs.collapse', function () {
            setTimeout(function () {
              // canvas.removeAttribute('height');
              // canvas.removeAttribute('width');
              chart.destroy();
              drawChart();
            }, 250);
          });

        });

      }
    }
  })


  .directive('healthGraphStacked', function ($http, $rootScope) {
    return {
      template: `
<div>
    <canvas-container></canvas-container>
</div>
`,
      restrict: 'E',
      scope: {
        query: '='
      },
      // transclude: true,
      link: function ($scope, element, attrs, ctrl, transclude) {
        $scope._ = _;

        var canvasContainer = element[0].querySelectorAll('canvas-container')[0];
        // var canvas = element[0].querySelectorAll('canvas')[0];

        var colorByValue = {
          "Yes": '#1B647E',
          "No": '#EF843B',
          "No response": 'rgb(154,154,154)',
        }

        var baseColors = cabriColors;

        var colors = [];

        $scope.query.height = $scope.query.height || 300;

        // console.log('element', canvas);

        $http.get('/_debt-frontend.php/health/answer-totals?query=' + encodeURIComponent(JSON.stringify($scope.query))).then((resp) => {
          $scope.data = resp.data;

          function randomScalingFactor() {
            return Math.random() * 1000;
          };


          var datasets = [];

          var remap = {};
          // var labels = _.keys(resp.data.questions[0].answerByValue);
          var labels = _.map(resp.data.questions, 'question_sub');

          // _.forEach(resp.data.questions, (question) => {
          //   _.forEach(question.answerByValue, (answers, value) => {
          //     remap[value] = remap[value] || {};
          //     remap[value][question.question_sub] = answers.length;
          //   });
          //
          // });

          var answersByValue = {};
          var labelsByDataset = [];
          var valueLabels = [];

          var countryIndex = 0;
          _.forEach(resp.data.questions, (question) => {
            var datasetIndex = 0;
            console.log('_.forEach(resp.data.questions, (question) => ', question);

            valueLabels = _.keys(question.answerByValue);

            _.forEach(question.answerByValue, (answers, value) => {
              answersByValue[value] = answersByValue[value] || [];
              answersByValue[value].push(answers.length);

              var countries = _.map(answers, function (item) {
                return item.country.name;
              });
              labelsByDataset[datasetIndex] = labelsByDataset[datasetIndex] || [];
              labelsByDataset[datasetIndex][countryIndex] = countries;
              datasetIndex++;
            })
            countryIndex++;
          });

          console.log('answersByValue', answersByValue);

          var i = 0;
          _.forEach(answersByValue, function (values, value) {
            var color = value === "No response" ? 'rgb(154,154,154)' : cabriColors[i];

            datasets.push({
              label: value,
              backgroundColor: color,
              data: values,
              borderWidth: 0,
            });
            i++;

          });

          // console.log('remap', remap);

          // _.forEach(resp.data.questions, (question) => {
          //   var counts = [];
          //
          //   datasets.push({
          //     label: value,
          //     // backgroundColor: "#ff0000",
          //     data: _.values(question)
          //   });
          //
          // });


          var barChartData = {
            labels: labels,
            datasets: datasets

          };

          console.log('barChartData', barChartData);

          var chart;

          function drawChart() {
            $(canvasContainer).html('<canvas height="300px" style="width: 100%; height: ' + $scope.query.height + 'px;"></canvas>');
            var ctx = $(canvasContainer).find('canvas')[0].getContext("2d");

            chart = new Chart(ctx, {
              type: 'bar',
              data: barChartData,
              options: {
                title: {
                  display: false,
                  // text: 'Chart.js Bar Chart - Stacked'
                },
                legend: {
                  display: true,
                },
                tooltips: {
                  mode: 'point',
                  intersect: false,
                  enabled: false,
                  custom: customTooltipFn,
                  callbacks: {
                    title: function (tooltipItem) {
                      console.log('tooltipItem title', labels, tooltipItem, tooltipItem.datasetIndex, tooltipItem.index);

                      return valueLabels[tooltipItem[0].datasetIndex];
                    },
                    label: function (tooltipItem, data) {
                      console.log('tooltipItem', tooltipItem);
                      console.log('callbacks label', tooltipItem.index, tooltipItem.datasetIndex, '-->', labelsByDataset, labelsByDataset[tooltipItem.index][tooltipItem.datasetIndex]);
                      // return labelsByDataset[tooltipItem.datasetIndex][tooltipItem.index];
                      return labelsByDataset[tooltipItem.datasetIndex][tooltipItem.index];
                    }
                  }
                },
                responsive: false,
                scales: {
                  xAxes: [{
                    stacked: true,
                  }],
                  yAxes: [{
                    stacked: true
                  }]
                }
              }
            });

          }

          drawChart();

          $(window).on('shown.bs.collapse', function () {
            setTimeout(function () {
              // canvas.removeAttribute('height');
              // canvas.removeAttribute('width');
              chart.destroy();
              drawChart();
            }, 250);
          });


          // var labels = [];
          // // var data = [];
          // var answersArr = [];
          // var datasets = [];
          // var labels = [];
          //
          // _.forEach(resp.data.questions, (question) => {
          //   var data = [];
          //   var colors = [];
          //
          //   _.forEach(question.answerByValue, (answers, value) => {
          //     value = value.split("\n");
          //     labels.push(value);
          //     data.push(answers.length);
          //     answersArr.push(answers);
          //
          //     if (colorByValue[value]) {
          //       colors.push(colorByValue[value]);
          //     }
          //   });
          //
          //   datasets.push({
          //     label: question.question_sub,
          //     // backgroundColor: colors,
          //     // borderColor: [],
          //     borderWidth: 0,
          //     data: data,
          //   });
          //
          // });
          //
          // colors = colors.concat(baseColors);
          //
          // var chartData = {
          //   // labels: labels,
          //   datasets: datasets
          // };
          //
          // console.log('chartData', chartData);
          //
          // // var ctx = canvas;
          // var type = 'bar';
          // var plugins = [];
          //
          // var options = {
          //   responsive: false,
          // };
          //
          // options.tooltips = {
          //
          //   enabled: false,
          //
          //   custom: customTooltipFn,
          //
          //   callbacks: {
          //     label: function(tooltipItem, data) {
          //       console.log('label', tooltipItem, data);
          //
          //       var countries = answersArr[tooltipItem.index];
          //
          //       var labels = _.map(countries, (item) => item.country.name);
          //
          //       return labels;
          //     }
          //   }
          // };
          //
          // options.scales =  {
          //   xAxes: [{
          //     stacked: true,
          //   }],
          //   yAxes: [{
          //     stacked: true
          //   }]
          // };
          //
          // options.scales = {
          //   yAxes: [{
          //     ticks: { beginAtZero: true },
          //     stacked: true,
          //   }],
          //   xAxes: [{
          //     stacked: true,
          //     gridLines : {
          //       display : false
          //     },
          //     ticks: {
          //       beginAtZero: true
          //     }
          //   }]
          // };
          //
          // if ($scope.query.labelRotation) {
          //   options.scales.xAxes[0].ticks.maxRotation = $scope.query.labelRotation;
          //   options.scales.xAxes[0].ticks.minRotation = $scope.query.labelRotation;
          // }
          //
          // var chart;
          //
          // function drawChart() {
          //   $(canvasContainer).html('<canvas style="width: 100%; height: ' + $scope.query.height + 'px;"></canvas>');
          //
          //   chart = new Chart($(canvasContainer).find('canvas')[0].getContext("2d"), {
          //     plugins: plugins,
          //     type: type,
          //     data: chartData,
          //     options: options,
          //   });
          // }
          //
          // drawChart();
          //
          // $(window).on('shown.bs.collapse', function () {
          //   setTimeout(function () {
          //     // canvas.removeAttribute('height');
          //     // canvas.removeAttribute('width');
          //     chart.destroy();
          //     drawChart();
          //   }, 250);
          // });

        });

      }
    }
  })


  .directive('healthAnswersTable', function ($http, $rootScope) {
    return {
      template: `
<table class="health-table">

    <tr class="group-heading-row group-heading-row-second">
    <th>Country</th>
    <th ng-repeat="question in data.questions" class="text-center" style="width: {{ 100/(data.questions.length+1) }}%">
    {{ question.question_sub || question.question }}
    </th>
    </tr>
    <tr ng-repeat="country in data.countries" class="country-row">
        <th class="country-name">{{ country.name }}</th>
        <td ng-repeat="question in data.questions" class="text-center">
            {{ country.answers[question.code].answer }}
        </td>
    </tr>
</table>`,
      restrict: 'E',
      scope: {
        query: '='
      },
      // transclude: true,
      link: function ($scope, element, attrs, ctrl, transclude) {
        console.log('healthAnswersTable', $scope);

        $http.get('/_debt-frontend.php/health/answer-table?query=' + JSON.stringify($scope.query)).then((resp) => {
          $scope.data = resp.data;
          $scope._ = _;
        });
      }
    }
  })


;
