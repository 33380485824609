
$(function () {

  var svg = document.querySelector("svg"),
    svgMap = document.getElementById('africa-map'); // #africa-map

  if (svgMap) {


    var active = svgMap.dataset.active;
    var complete = svgMap.dataset.completed;
    var ongoing = svgMap.dataset.ongoing;


    if (active) {
      console.log('active dataset', active);
      var activePath = svgMap.querySelector('.' + active);

      if (activePath) {
        activePath.classList.add('active');
      }
    }

    if (complete) {
      console.log('complete dataset', complete);
      var selectors = complete.split(',');
      for (var i = 0; i < selectors.length; i++) {
        var selector = selectors[i];
        var selectorPath = svgMap.querySelector('.' + selector);
        if (selectorPath) {
          selectorPath.classList.add('active');
        }
      }
    }

    if (ongoing) {
      console.log('ongoing dataset', ongoing);
      var selectors = ongoing.split(',');
      for (var i = 0; i < selectors.length; i++) {
        var selector = selectors[i];
        var selectorPath = svgMap.querySelector('.' + selector);
        if (selectorPath) {
          selectorPath.classList.add('active');
          selectorPath.classList.add('alpha-50');
        }
      }
    }

    // Add navigation to reports

    $(svgMap).find('[data-iso]').each(function () {
      console.log('data-iso', $(this));
      var isoCode = $(this).data('iso');

      if (window.countryTitles[isoCode]) {
        var $tooltip;
        $(this).on('mouseover', function () {
          console.log("mouseover", $(this).offset());
          $tooltip = $("<div class='tooltip in top'><div class='tooltip-inner'>"+window.countryTitles[isoCode]+"</div><div class='tooltip-arrow'></div></div>");

          var offsetTop = $(this).offset().top;
          var offsetLeft = $(this).offset().left;
          var width = $(this)[0].getBoundingClientRect().width;
          var height = $(this)[0].getBoundingClientRect().height;

          $tooltip.css({position: 'absolute'});
          $('body').append($tooltip);
          var toolW = $tooltip.width();
          var toolH = $tooltip.height();

          $tooltip.css({
            top: (offsetTop - 30) + "px",
            left: (offsetLeft + (width - toolW)/2) + "px",

          })

        });
        $(this).on('mouseout', function () {
          if ($tooltip) {
            $tooltip.remove();
            $tooltip = null;
          }
        });
      }



      if (window.countryPublications && window.countryPublications[isoCode]) {
        $(this).addClass("svg-map-link");
        $(this).on('click', function () {
          window.location.href = window.countryPublications[isoCode];
        })

      }

    });

  }

})